import React from "react"

import Layout from "../components/Layout"
import Logo from "../components/Logo"
import SEO from "../components/Seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Dream Journal App | Dreamjot" />
    <div className="container">
      <div className="centered-content">
        <div style={{ width: 150, marginBottom: "1.1rem" }}>
          <Logo />
        </div>
        <h1>Dreamjot</h1>
        <h2 style={{ margin: 0 }}>Dream Journal App and Sleep Tracker</h2>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            flexWrap: "wrap",
            justifyContent: "center",
          }}
        >
          <a href="https://play.google.com/store/apps/details?id=com.ubiquitools.dreamjot">
            <img
              alt="Get it on Google Play"
              style={{ width: "200px" }}
              src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
            />
          </a>
          <a href="https://apps.apple.com/us/app/dreamjot/id1501776999">
            <div
              style={{
                overflow: "hidden",
                background:
                  "url(https://linkmaker.itunes.apple.com/assets/shared/badges/en-us/appstore-lrg.svg) no-repeat",
                width: "175px",
                height: "60px",
                backgroundSize: "contain",
              }}
            ></div>
          </a>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
